<template>
  <b-card>
    <resort-form
      :resort-obj="resort"
      @clearAction="action = null"
      :action="action"
    />
  </b-card>
</template>

<script>
import ResortForm from "./components/ResortForm.vue";
import { BCard } from "bootstrap-vue-3";

export default {
  components: {
    ResortForm,
    BCard,
  },
  data() {
    return {
      action: 'add',
      resort: {
        status: 'ACTIVE',
        name: null,
        url: '',
        location: null,
        stars: null,
        review: null,
        availableUntil: null,
        benefits: [],
        facilitiesServices: [],
        description: null,
        transferDescription: null,
        accommodationDescription: null,
        speedboatTransfer: {
          adultPrice: 0,
          childPrice: 0,
          infantPrice: 0,
        },
        seaplaneTransfer: {
          adultPrice: 0,
          childPrice: 0,
          infantPrice: 0,
        },
        domesticFlightTransfer: {
          adultPrice: 0,
          childPrice: 0,
          infantPrice: 0,
        },
      },
      tabs: [
        {
          id: 0,
          title: "Resort",
        },
        {
          id: 1,
          title: "Rooms",
        },
      ],
      tabIndex: 1,
    };
  },
};
</script>
